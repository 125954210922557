.ui.dropdown {
  &.datepicker-dropdown {
    .menu {
      box-shadow: none;
    }
  }
}

@media all and (max-width: @tabletBreakpoint) {
  .ui.dropdown {
    min-width: 0 !important;
  }
}
