.ui.modal {
  &.form-modal.community-invitations {
    .invenio-text-area-field {
      margin-bottom: 0 !important;
      .field {
        margin-bottom: 1rem !important;
      }
    }
  }
  &.communities.community-selection-modal {
    .communities.communities-list-scroll-container {
      max-height: 50vh;
      overflow-y: auto;
    }
  }
}
